import React from 'react';

interface PdfDocumentProps {
  url: string;
}

function PdfDocument({ url }: PdfDocumentProps): JSX.Element {
  return <iframe title='pdf' src={url} width='100%' height='800px' />;
}

export default PdfDocument;
