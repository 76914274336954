import React from 'react';

interface AudioPlayerProps {
  url: string;
}

function AudioPlayer({ url }: AudioPlayerProps): JSX.Element {
  return (
    <audio controls style={{ width: '100%' }}>
      <track default kind='captions' />
      <source src={url} type='audio/mpeg' />
      Your browser does not support the audio element.
    </audio>
  );
}

export default AudioPlayer;
